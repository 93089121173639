import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LangService } from '@shared/services/lang/lang.service';

@Injectable({
  providedIn: 'root'
})
export class SharedSearchService {
  constructor(private http: HttpClient, private langService: LangService) {}

  getSharedSearch(codigo: string) {
    return this.http.get<any>( this.langService.getUrlLang() + `/config-table-view/search-url/${codigo}`, {});
  }

  newSharedSearch(configTableModule: string, body: any): any {
    return this.http.post<any>( this.langService.getUrlLang() + `/config-table-view/search-url/${configTableModule}`, body);
  }
}
