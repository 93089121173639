import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root', // Hace que el servicio esté disponible globalmente
})
export class LangService {
  constructor( private translate: TranslateService) {}

 getUrlLang(){

  const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      const objetoUsuario = JSON.parse(currentUser);
    if(objetoUsuario && objetoUsuario.language && objetoUsuario.language.code){
      return environment.backendUrl.replace('/v1', '/'+objetoUsuario.language.code + '/v1');
    }else{
      return environment.backendUrl.replace('/v1', '/es/v1');
    }
  
    }

    return environment.backendUrl.replace('/v1', '/es/v1');

  }

  getUserLang(){

    const currentUser = localStorage.getItem('currentUser');
      if (currentUser) {
        const objetoUsuario = JSON.parse(currentUser);
      if(objetoUsuario && objetoUsuario.language && objetoUsuario.language.code){
        return objetoUsuario.language.code;
      }else{
        return 'es';
      }
      }
      return 'es';
  
    }
}
