/*** @angular ***/
import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';

/*** @pages ***/
import { LoginModule } from '@pages/login/login.module';
import { LangService } from '@shared/services/lang/lang.service';

/*** @shared ***/
import { SpinnerOverlayComponent } from '@shared/components/spinner-overlay/spinner-overlay.component';
import { DynamicLocaleIdUtil } from '@shared/utils/dynamic-locale-id.util';
import { SharedModule } from '@shared/components/shared.module';
import { ModalServiceFromComponentComponent } from '@shared/components/modal/modal-service-from-component/modal-service-from-component.component';

/*** @interceptors ***/
import { ErrorInterceptor } from '@interceptors/error.interceptor/error.interceptor';
import { AppHttpInterceptor } from '@interceptors/http.interceptor/http.interceptor';
import { JwtInterceptor } from '@interceptors/jwt.interceptor/jwt.interceptor';
import { HeaderInterceptor } from '@interceptors/header.interceptor/header.interceptor';
import { CacheInterceptor } from '@interceptors/cache.interceptor/cache.interceptor';

/*** @ngx ***/
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';

/*** @Azure ***/
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalRedirectComponent
} from '@azure/msal-angular';

/*** Others ***/
import { RootStoreModule } from '@store/store.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CustomErrorHandler } from './handlers/errors/custom-error-handler';
import { AngularSvgIconPreloaderModule } from '../vendor/angular-svg-icon-preloader/angular-svg-icon-preloader.module';
import { AuthLoginGuardService } from './guards/auth-login-guard.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerModule } from '@shared/components/spinner/spinner.module';
import { ToastrModule } from 'ngx-toastr';
import { DomainService } from './shared/services/domain/domain.service';
import { DomainsEnum } from './shared/models/common/domains.enum';
import { JwtOatuh2Interceptor } from '@interceptors/jwt.interceptor/jwtOatuh2.interceptor';
import { mSALGuardConfigFactory, mSALInstanceFactory, mSALInterceptorConfigFactory } from '@shared/config/msal.config';
import { ClickOutsideDirectiveModule } from '@shared/directives/ClickOutsideDirective/click-outside-directive.module';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpBackend) {

  const domain = DomainService.getDomain();

  if(domain == DomainsEnum.AXESS){
    return new MultiTranslateHttpLoader(http, [
      { prefix: '../../assets/i18n/axess/login/', suffix: '.json' },
      { prefix: '../../assets/i18n/axess/', suffix: '.json' }
    ]);
  }else{
    return new MultiTranslateHttpLoader(http, [
      { prefix: '../../assets/i18n/nms/login/', suffix: '.json' },
      { prefix: '../../assets/i18n/nms/', suffix: '.json' }
    ]);
  }

}

registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeEn, 'pt');

@NgModule({
  declarations: [AppComponent, ModalServiceFromComponentComponent, SpinnerOverlayComponent],
  imports: [
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: '../assets/icons/svg-icons.json'
    }),
    OverlayModule,
    BrowserModule,
    BrowserAnimationsModule,
    LoginModule,
    AppRoutingModule,
    RootStoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend]
      },
      isolate: false
    }),
    SharedModule,
    SpinnerModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    MsalModule,
    ClickOutsideDirectiveModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtOatuh2Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleIdUtil,
      deps: [TranslateService]
    },
    BsModalRef,
    AuthLoginGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: mSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: mSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: mSALInterceptorConfigFactory },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    LangService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
