import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { LoginActionTypes, loginStartSuccess } from './login.actions';
import { AuthenticationService } from '@services/authentication.service/authentication.service';
import { User } from '@shared/models/users/user.model';
import { RoleEnum } from '@shared/models/users/role.enum';
import { of } from 'rxjs';
import * as fromActions from './login.actions';
import { CustomErrorHandler } from 'src/app/handlers/errors/custom-error-handler';
import { LangService } from '@shared/services/lang/lang.service';

@Injectable()
export class LoginEffects {
  constructor(private actions$: Actions, private router: Router, protected authService: AuthenticationService, private langService: LangService) {}

  requestLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActionTypes.loginStart),
      switchMap((action: { email: string; password: string; domain: string }) => {
        return this.authService.auth(action.email, action.password, action.domain).pipe(
          map((user: User) => {
            return loginStartSuccess({ user: user });
          })
        );
      })
    );
  });

  requestLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginActionTypes.loginStartSuccess),
        tap(() => {
          if (this.authService.checkUserOnlyRole(RoleEnum.ROLE_INSTALLER)) {
            this.router.navigate([this.langService.getUserLang() +'/terminals']);
          } else {
            this.router.navigate([this.langService.getUserLang() +'/dashboard']);
          }
        })
      ),
    { dispatch: false }
  );

  requestTokenAzure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActionTypes.tokenAzure),
      switchMap(() => {
        return this.authService.getTokenAzure().pipe(
          map((user: User) => {
            return loginStartSuccess({ user: user });
          })
        );
      })
    );
  });
}
