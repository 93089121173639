import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
  HostListener,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EnumUtils } from 'src/app/shared/utils/enum-utils';
import { DropDownSelected } from '../dropdown/interfaces/dropdown-selected.interface';
import { Action } from './interfaces/action.model';
import { ActionsEnum } from './interfaces/actions.enum';
import { ActionsTitleEnum } from './interfaces/actions-title.enum';
import { CheckModel } from './interfaces/check.model';
import { ConfigParamsTable } from './interfaces/config-params-table.model';
import { OrderDirEnum } from './interfaces/order-dir.enum';
import { StatesClassEnum } from './interfaces/states-class.enum';
import { TableConfig } from './interfaces/table-config.model';
import { TypeEnum } from './interfaces/type.enum';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OptionalColumnDataHeadModel } from './interfaces/optional-column-data-head.model';
import { DropDownSelectedItem } from '../dropdown/interfaces/dropdown-selected-item.model';
import { DropDownMultipleSelected } from '../dropdown/interfaces/dropdown-multiple-selected.interface';
import { DropdownItem } from '../dropdown/interfaces/dropdown-item.model';
import { DropdownMenuItem } from '../dropdown-menu/interfaces/dropdown-menu-item.model';
import { TemplatePortal } from '@angular/cdk/portal';
import { PortalService } from '@shared/components/portal/portal.service';
import { DropDown } from '../dropdown/interfaces/dropdown.model';
import { searchModifiersUtil } from './utils/search-modifiers.util';
import { PortalsUtils } from '@shared/utils/portal.utils';
import { Store } from '@ngrx/store';
import * as fromActions from '@shared/components/table/store/table.actions';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { SharedSearchService } from './services/shared-search.service';
import { DatePickerBsType } from '../date-picker-bs/date-picker-bs-type.enum';
import { DomainService } from '../../services/domain/domain.service';
import { DomainsEnum } from '../../models/common/domains.enum';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChildren('thItemRef', { read: ElementRef }) thItemRef!: QueryList<ElementRef>;
  @ViewChildren('tdItemRef', { read: ElementRef }) tdItemRef!: QueryList<ElementRef>;
  @ViewChildren('textItemRef', { read: ElementRef }) textItemRef!: QueryList<ElementRef>;
  @ViewChild('headerWrap') headerTableElem!: ElementRef;

  @Output() search = new EventEmitter<any>();
  @Output() action = new EventEmitter<Action>();
  @Output() clickColumn = new EventEmitter<any>();
  @Output() link = new EventEmitter<any>();
  @Output() columnNoOrdenable = new EventEmitter<any>();
  @Output() editField = new EventEmitter<any>();

  @Input() minitable = false;
  @Input() data = new Array<any>();
  @Input() searchParams?: any = {};
  @Input() showEmpty = true;
  @Input()
  set config(config: ConfigParamsTable) {
    this._config = this.prepareEnv(config);
  }
  get config(): ConfigParamsTable {
    return this._config;
  }

  _config!: any;
  searchInput$ = new Subject<any>();
  pageCurrent = 1;
  filling: any;
  isSearchColumn = false;
  typeEnum = TypeEnum;
  checkAll = false;
  checkedModel = Array<CheckModel>();
  eventShowMenuSubject: Subject<void> = new Subject<void>();
  dropdownsEvent = Array<Subject<void>>();
  showEmptyResult = false;
  firstEntry = true;
  pendingAction = false;
  optionsSearchModifiers = {} as any;
  overflowHeader = false;
  dataOptions = new Array<DropdownMenuItem>();
  teleportedTemplates: Array<TemplatePortal> = [];
  isDotOptions = false;
  showButtonClearFilters = false;
  searchParams$: Subject<any> = new Subject<void>();
  datePickerBsTypeMONTHLY = DatePickerBsType.MONTHLY;

  public optionsFormMultiple: any[] = [];
  public selectOptionBoolean?: boolean;
  public selectOptionUnique?: string;

  private scrollTop = 0;
  private typesSearchable = [
    TypeEnum.INPUT,
    TypeEnum.DATE_FILTER,
    TypeEnum.DATE_MONTH_FILTER,
    this.typeEnum.COMBO_MULTIPLE
  ].map(t => t.toString());
  private clearfilters$ = new Subscription();
  private searchParamsOLD: any = {};
  private hasParamSearch = false;

  showInput: any[] = [];
  lastIndex: any;
  nameEditable: any;
  columnName: any;
  domainAxess = false;
  domainNms = true;


  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private portalService: PortalService,
    private cd: ChangeDetectorRef,
    private store: Store,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private sharedSearchService: SharedSearchService
  ) {
    this.translate.use(this.translate.store.currentLang);
    this.translate.store.onLangChange.subscribe(lang => this.translate.use(lang.lang));
    this.searchInput$.pipe(debounceTime(1500)).subscribe(lastKeyupParams => this.executeHeadSearch(lastKeyupParams));
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    this.headerTableElem.nativeElement.scrollTo(event.target.scrollLeft, 0); // synchronizing header horizontal scroll
    PortalsUtils.closeDropdown();
    this.scrollTop = event.target.scrollTop;
  }

  ngOnInit() {
    this.checkDomain();
    this.config.tableConfigs.forEach((element: any, index: any) => {
      this.showInput[index] = [];
    });

    this.dataOptions.push(new DropdownMenuItem('delete', 'COMMONS.DELETE'));
    this.firstEntry = false;
    if (this.searchParams['page'] === undefined) {
      this.pageCurrent = this.config.page + 1;
      this.searchParams['page'] = this.config.page;
    }
    if (this.searchParams['size'] === undefined) {
      this.searchParams['size'] = this.config.size;
    }
    this.pageCurrent = this.searchParams['page'];

    if (
      this.searchParams['selected'] !== undefined &&
      this.searchParams['selected'] <= this.config.tableConfigs.length
    ) {
      this.config.tableConfigs[this.searchParams['selected']].selected = true;
      this.config.tableConfigs[this.searchParams['selected']].orderDir = this.searchParams['orderDir'];
    }

    this.selectColumnByDefault();
    this.isSearchColumn = this.config.tableConfigs.some(item => (item.search ? true : item?.type === 'combo'));

    this.createOptionsSearchModifiers();

    if (this.config) {
      const dropDownTypes = [
        TypeEnum.INPUT,
        TypeEnum.DATE_FILTER,
        TypeEnum.COMBO,
        TypeEnum.COMBO_LOADING,
        TypeEnum.COMBO_MULTIPLE,
        TypeEnum.COMBO_BOOLEAN
      ].map(t => t.toString());
      this.overflowHeader = this.config.tableConfigs.some(config => dropDownTypes.includes(config.type));
    }

    this.clearfilters$ = this.store
      .select((state: any) => state.shared.table[this.config.module])
      .subscribe((modulo: any[]) => {
        if (modulo) setTimeout(() => (this.showButtonClearFilters = modulo.length > 0), 0);
      });

    // fix para el resize de columna
    const head = this.el.nativeElement.querySelectorAll(`th.p-0.column-head`);
    head.forEach((th: HTMLElement) => setTimeout(() => (th.style.minWidth = `${th.offsetWidth}px`), 0));

    this.parameterSearchSubscribe();
  }

  private parameterSearchSubscribe() {
    this.route.queryParams.subscribe((params: Params) => {
      this.hasParamSearch = params['search'];
      if (this.hasParamSearch) {
        this.sharedSearchService.getSharedSearch(params['search']).subscribe(response => {
          this.onSelectQuery(response.data.query);
        });
      }
    });
  }

  ngAfterViewInit() {
    this.store.dispatch(fromActions.tableFiltersReset({ module: this.config.module }));

    this.setPositionStickyColumn();

    // Se suprime trackby debido a que al ponerlo no se pueden obtener los cambios en el dom por ElementRef.
    // Se utiliza change del ElementRef debido a que en el momento de tomar el tamaño del widht de las columnas en afterViewInit todavía no se
    // han aplicado los estilos y lo que se toma es una imagen inicial de la posición.
    // Se retira trackby para poner el estilo en el render.

    this.thItemRef.changes.pipe().subscribe(itemsRef => {
      let widthColumn = 0;
      itemsRef._results.forEach((itemRef: any, colIndex: any) => {
        if (colIndex < this.config.numColumnSticky) {
          this.renderer.setStyle(itemRef.nativeElement, 'left', widthColumn + 'px');
          widthColumn = itemRef.nativeElement.offsetWidth + widthColumn;
        }
      });
    });

    // this.textItemRef.changes.pipe().subscribe(itemsRef => {
    //   itemsRef.forEach((itemRef: any) => {
    //     const children = itemRef.nativeElement.children;
    //     if (
    //       children &&
    //       children.length > 0 &&
    //       itemRef.nativeElement.offsetWidth - 10 > children[0].offsetWidth &&
    //       children[0].offsetWidth > 0
    //     ) {
    //       this.renderer.setStyle(itemRef.nativeElement, 'width', children[0].offsetWidth + 10 + 'px');
    //     }
    //   });
    // });

    this.portalService.getSourceTemplates().subscribe(sourceTemplates => {
      if (sourceTemplates) {
        this.teleportedTemplates = sourceTemplates;
        this.cd.detectChanges();
      }
    });
  }

  ngOnChanges() {
    if (this.data && this.data.length > 0) {
      setTimeout(() => (this.showEmptyResult = false), 100);
    } else if (!this.firstEntry && !this.pendingAction) {
      setTimeout(() => (this.showEmptyResult = true), 100);
    }

    if (this.data) {
      this.dropdownsEvent?.forEach(element => {
        element.complete();
      });
      this.dropdownsEvent = new Array<Subject<void>>();
      this.data.forEach(() => {
        this.dropdownsEvent.push(new Subject<void>());
      });
      const elements = this.config.size - this.data.length;
      this.filling = [];
      this.pageCurrent = this.config.page + 1;
      if (elements >= 0) {
        this.filling = new Array(this.config.size - this.data.length).fill(0).map((e, index) => index);
      }

      if (this.config.tableConfigs.some(config => config.type === TypeEnum.MASS_CHECK)) {
        if (!this.searchParams['checked']) {
          this.searchParams['checked'] = new Array<CheckModel>();
        }
        this.prepareMassCheck();
      }

      const configs = this._config.tableConfigs.find((register: any) => register.name === 'actions');

      if (configs && configs.actions) {
        const arrayActions = new Array<any>();
        configs.actions.forEach((element: any) => {
          arrayActions.push(element);
        });
        const numActions = arrayActions.length;

        this.data?.forEach(data => {
          let dotOptions = false;
          let excludeActions = [];
          if (data.excludeActions) {
            excludeActions = data.excludeActions;
          }

          if (numActions - 1 - excludeActions?.length >= 2) {
            dotOptions = true;
          }

          if (dotOptions) {
            this.isDotOptions = true;
            data.dotOptions = [];
            if (!data.excludeActions) {
              data.excludeActions = [];
            }
            for (let i = 0; i < arrayActions.length - 1; i++) {
              let encontrado = false;
              excludeActions.forEach((excludeOption: any) => {
                if (arrayActions[i] === excludeOption) {
                  encontrado = true;
                }
              });
              if (!encontrado && i > 1) {
                data.dotOptions.push(
                  new DropdownMenuItem(
                    arrayActions[i],
                    EnumUtils.getValueByKeyForStringEnum(arrayActions[i], ActionsTitleEnum)
                  )
                );
                data.excludeActions?.push(arrayActions[i]);
              }
            }


            let contador = 0;
            for (let i = 0; i < configs._actions.length-1 ; i++) {
              let encontrada = false;
              for (let y = 0; y < data.excludeActions?.length ; y++) {
                if(configs._actions[i] == data.excludeActions[y] ){
                  encontrada = true;
                }
              }
              if(!encontrada){
                contador++
              }
            }

            if(contador == 1){
              data.excludeActions.splice(data.excludeActions.indexOf(data.dotOptions[0].id), 1);
               data.dotOptions.splice(0, 1);
            }

            if(contador == 0 && data.dotOptions?.length > 0 && data.dotOptions?.length <=3){

              for (let y = 0; y < data.dotOptions?.length ; y++) {
                data.excludeActions = data.excludeActions.filter((item:any) => item != data.dotOptions[y].id);
        
              }
              
              data.excludeActions?.push(ActionsEnum.OPTIONS);
            }

          
            // if (configs._actions?.length - data.excludeActions?.length > 2 &&  data.dotOptions?.length > 0) {
            //   data.excludeActions.splice(data.excludeActions.indexOf(data.dotOptions[0].id), 1);
            //   data.dotOptions.splice(0, 1);
            // }
          } else {
            data.excludeActions?.push(ActionsEnum.OPTIONS);
          }
        });
      }

      // if (configs && configs.actions) {
      //   const arrayActions = new Array<any>();
      //   configs.actions.forEach((element: any) => {
      //     arrayActions.push(element);
      //   });
      //   const numActions = arrayActions.length;

      //   this.data?.forEach(data => {
      //     let dotOptions = false;
      //     let excludeActions = [];
      //     if (data.excludeActions) {
      //       excludeActions = data.excludeActions;
      //     }

      //     if (numActions - 1 - excludeActions?.length >= 3) {
      //       dotOptions = true;
      //     }

      //     if (dotOptions) {
      //       this.isDotOptions = true;
      //       data.dotOptions = [];
      //       if (!data.excludeActions) {
      //         data.excludeActions = [];
      //       }
      //       for (let i = 0; i < arrayActions.length - 1; i++) {
      //         let encontrado = false;
      //         excludeActions.forEach((excludeOption: any) => {
      //           if (arrayActions[i] === excludeOption) {
      //             encontrado = true;
      //           }
      //         });
      //         if (!encontrado && i > 1) {
      //           data.dotOptions.push(
      //             new DropdownMenuItem(
      //               arrayActions[i],
      //               EnumUtils.getValueByKeyForStringEnum(arrayActions[i], ActionsTitleEnum)
      //             )
      //           );
      //           data.excludeActions?.push(arrayActions[i]);
      //         }
      //       }

      //       if (configs._actions?.length - data.excludeActions?.length > 2 &&  data.dotOptions?.length > 0) {
      //         data.excludeActions.splice(data.excludeActions.indexOf(data.dotOptions[0].id), 1);
      //         data.dotOptions.splice(0, 1);
      //       }
      //     } else {
      //       data.excludeActions?.push(ActionsEnum.OPTIONS);
      //     }
      //   });
      // }

      // fix para el resize de columna
      setTimeout(() => {
        const head = this.el.nativeElement.querySelectorAll(`th.p-0.column-head`);
        head.forEach((th: HTMLElement) => {
          th.style.minWidth = `${th.offsetWidth}px`;
        });
      }, 0);
    }

    if (this.searchParams) {
      // this.searchParams$.next(this.searchParams)
      this.searchModifiers();
    }

    this.pendingAction = false;
  }

  ngOnDestroy() {
    this.clearfilters$.unsubscribe();
  }

  setPositionStickyColumn() {
    this.tdItemRef.changes.pipe().subscribe(itemsRef => {
      let widthColumn = 0;
      for (
        let col = 0;
        col <= Math.ceil(itemsRef.length / this.searchParams['size']) && col < this.config.numColumnSticky;
        col++
      ) {
        const elements: ElementRef[] = itemsRef.filter((ref: any) =>
          ref.nativeElement.className.includes('col' + col + ' ')
        );
        elements.forEach(element => {
          this.renderer.setStyle(element.nativeElement, 'left', widthColumn + 'px');
        });
        if (elements.length > 0) {
          widthColumn = widthColumn + elements[0].nativeElement.offsetWidth;
          // widthColumn = widthColumn + elements[0].nativeElement.offsetWidth - 26;
        }
      }
    });
  }

  prepareMassCheck() {
    this.checkAll = false;
    this.checkedModel = Array<CheckModel>();
    if (this.data.length > 0) {
      this.searchParams['checked'] = this.searchParams['checked'].filter((check: any) => check.checked);
    }
    this.data.forEach(data => {
      const checkModel: CheckModel = this.searchParams['checked'].find((cm: any) => cm.id === data['id'], data);
      if (checkModel) {
        this.checkedModel.push(checkModel);
      } else {
        const newCheckModel: CheckModel = new CheckModel(data['id'], false, data);
        this.searchParams['checked'].push(newCheckModel);
        this.checkedModel.push(newCheckModel);
      }
    });
    this.checkAll = this.isAllCheckSelected();
  }

  getClassThHead(paramsConf: TableConfig) {
    return {
      selected: paramsConf.selected,
      'cursor-pointer': paramsConf.sort,
      'cursor-context-menu': !paramsConf.sort,
      'justify-content-between': paramsConf.type !== TypeEnum.MASS_CHECK,
      'justify-content-center': paramsConf.type === TypeEnum.MASS_CHECK
    };
  }

  selectColumnByDefault() {
    if (this.config.columnSelectDefault !== undefined) {
      let searchOrderSort: any = this.config.tableConfigs[this.config.columnSelectDefault].orderSort;
      if (!searchOrderSort) {
        searchOrderSort = this.config.tableConfigs[this.config.columnSelectDefault].search;
        this.config.tableConfigs[this.config.columnSelectDefault].orderSort = searchOrderSort;
      }
      if (!this.searchParams['orderDir']) {
        this.searchParams['orderDir'] = OrderDirEnum.ASC;
      }
      this.searchParams['orderSort'] = searchOrderSort;
      if (this.config.columnSelectDefault !== 0) {
        this.config.tableConfigs[0].selected = false;
      }
      this.config.tableConfigs[this.config.columnSelectDefault].selected = true;
      this.config.tableConfigs[this.config.columnSelectDefault].orderDir = OrderDirEnum.ASC;
      // if (searchOrderSort) {
      //   this.config.tableConfigs[this.config.columnSelectDefault].orderSort = searchOrderSort;
      // }
    }
  }

  pageChanged(event: any) {
    this.showEmptyResult = false;
    this.pendingAction = true;
    // this check if we change the number of elements pagination.
    if (this.pageCurrent <= Math.round(this.config.count / this.config.size) + 1) {
      // añadí el numero 1 porque la ultima pg siempre era mayor que la actual
      this.pageCurrent = event;
      this.config.page = event - 1;
      this.onPageChanged();
    }
  }
  pagePrevious() {
    this.pageCurrent--;
    this.config.page--;
    this.onPageChanged();
  }

  pageNext() {
    this.pageCurrent++;
    this.config.page++;
    this.onPageChanged();
  }

  onPageChanged() {
    this.showEmptyResult = false;
    this.pendingAction = true;
    this.searchParams['page'] = this.config.page;
    this.searchParams['size'] = this.config.size;
    this.searchEmit(this.searchParams);

    // fix para el resize de columna
    setTimeout(() => {
      const head = this.el.nativeElement.querySelectorAll(`th.p-0.column-head`);
      head.forEach((th: HTMLElement) => {
        th.style.minWidth = `${th.offsetWidth}px`;
      });
    }, 0);
  }

  prepareEnv(config: any) {
    if (!config) {
      config = new ConfigParamsTable();
    }
    config.tableConfigs.map((field: any, index: any) => {
      field.select = index === 0;
      field.orderDir = index === 0 ? OrderDirEnum.ASC : OrderDirEnum.DESC;
      if (field.actions) {
        field.actions = new Set(field.actions);
      }
      field.sort = this.isValidColumnClick(field);
      return field;
    });
    return config;
  }

  isValidColumnClick(paramsConf: TableConfig) {
    const sortableTypes = [
      TypeEnum.INPUT,
      TypeEnum.COMBO,
      TypeEnum.COMBO_MULTIPLE,
      TypeEnum.DATE,
      TypeEnum.DATE_TIME,
      TypeEnum.DATE_FILTER,
      TypeEnum.COMBO_BOOLEAN
    ].map(t => t.toString());
    return paramsConf.sort && (!paramsConf.type || sortableTypes.includes(paramsConf.type));
  }

  iconOrderClasses(paramsConf: TableConfig) {
    return {
      'icon-down-arrow-sm':
        paramsConf.orderDir === OrderDirEnum.ASC && paramsConf.selected && this.isValidColumnClick(paramsConf),
      'icon-up-arrow-sm':
        paramsConf.orderDir === OrderDirEnum.DESC && paramsConf.selected && this.isValidColumnClick(paramsConf)
    };
  }

  statesClasses(color: string) {
    const statesClassEnum = EnumUtils.getValueByKeyForStringEnum(color, StatesClassEnum);
    return { [statesClassEnum]: true };
  }

  onKeyHeadSearch(value: string, index: number) {
    this.showEmptyResult = false;
    this.pendingAction = true;
    const searchTC = this.config.tableConfigs[index].search;
    if (searchTC) {
      this.searchParams[searchTC] = value;
    }
    this.config.page = 0;
    this.searchParams['page'] = this.config.page;
    this.searchParams['size'] = this.config.size;
    this.searchInput$.next({ value: value, index: index });
  }

  onSingleClickColumn(paramsConf: TableConfig, index: number) {
    // this.showInput.forEach((item: any, index2) => {
    //   this.showInput[index2] = false;
    // });
    this.showInput.forEach((item2: any, index2) => {
      item2.forEach((item3: any, index3: any) => {
        this.showInput[index2][index3] = false;
      });
    });
    // to order table after listener clicks
    setTimeout(() => {
      if (this.config.isColumnClick && this.config.columnsId.length > 0 && index > 0) {
        this.columnNoOrdenable.emit(this.config.columnsId[index - 1]);
      } else if (this.isValidColumnClick(paramsConf)) {
        this.config.tableConfigs.forEach((pc, i) => {
          pc.selected = index === i;
        });
        this.searchParams['orderDir'] = paramsConf.orderDir =
          paramsConf.orderDir === OrderDirEnum.DESC ? OrderDirEnum.ASC : OrderDirEnum.DESC;
        this.searchParams['orderSort'] = paramsConf.orderSort;
        if (!paramsConf.orderSort && paramsConf.search) {
          this.searchParams['orderSort'] = paramsConf.search;
        }
        this.searchParams['selected'] = index;

        this.searchEmit(this.searchParams);
      }

      // fix para el resize de columna
      const head = this.el.nativeElement.querySelectorAll(`th.p-0.column-head`);
      head.forEach((th: HTMLElement) => {
        th.style.minWidth = `${th.offsetWidth}px`;
      });
    }, 0);
  }

  onDoubleClickColumn(paramsConf: TableConfig, index: number) {
    if (paramsConf.type === TypeEnum.MASS_CHECK || paramsConf.type === TypeEnum.ACTIONS) return;

    const widthDotElipsis = 24;
    const columnWidthDefault = 200;
    let offsetWidthMAX = 0;
    const bodyTd = this.el.nativeElement.querySelectorAll(`td.ps-2.column-table.col${index}`);

    // me quedo con el offsetWidth del texto mayor
    bodyTd.forEach((td: HTMLElement) => {
      const bodyTdDiv = td.querySelector('div');
      const bodyTdDivSpan = bodyTdDiv?.querySelector('span') as HTMLElement;
      if (bodyTdDivSpan && offsetWidthMAX < bodyTdDivSpan.offsetWidth) offsetWidthMAX = bodyTdDivSpan.offsetWidth;
    });

    if (offsetWidthMAX + widthDotElipsis <= columnWidthDefault) return;

    // ajusto las columnas de la cabecera cabecera
    const head = this.el.nativeElement.querySelectorAll(`th.p-0.column-head.col${index}`);
    head.forEach((th: HTMLElement) => {
      const columnWidth = paramsConf.isColumnExpan
        ? `${columnWidthDefault}px`
        : `${offsetWidthMAX + widthDotElipsis}px`;
      this.renderer.setStyle(th, 'min-width', columnWidth);

      const px1 = th.querySelector('.px-1');
      if (px1) {
        this.renderer.setStyle(px1, 'width', columnWidth);
        this.renderer.setStyle(px1, 'min-width', '');
      }
    });

    // ajusto las columnas del cuerpo cabecera
    const compTable = this.el.nativeElement.querySelector('.comp-table') as HTMLTableElement;
    const tableBodyContainer = compTable.querySelector('.table-body-container') as HTMLTableElement;
    const div = tableBodyContainer.querySelector(`th.p-0.column-head.col${index}>div`);
    this.renderer.setStyle(div, 'width', ``);
    this.renderer.setStyle(div, 'min-width', ``);

    // ajusto las columnas de cuerpo cuerpo
    bodyTd.forEach((td: HTMLElement) => {
      const bodyTdDiv = td.querySelector('div');
      this.renderer.setStyle(td, 'min-width', `${columnWidthDefault}px`);
      this.renderer.setStyle(bodyTdDiv, 'width', '');
    });

    paramsConf.isColumnExpan = !paramsConf.isColumnExpan;
  }

  onSelectedAction(action: Action) {
    action.search = this.searchParams;
    this.action.emit(action);
  }

  onSelectedLink(item: any, node: string) {
    this.link.emit({ item: item, node: node });
  }

  executeHeadSearch(lastKeyupParams: any) {
    const searchTC = this.config.tableConfigs[lastKeyupParams.index].search;
    if (searchTC) {
      this.searchParams[searchTC] = lastKeyupParams.value;
    }
    this.searchEmit(this.searchParams);
  }

  onDropDownSearch(index: number, dropDownSelected: DropDownSelected) {
    this.showEmptyResult = false;
    this.pendingAction = true;

    const searchTC = this.config.tableConfigs[index].search;
    if (searchTC) {
      if (this.typesSearchable.includes(this.config.tableConfigs[index].type)) {
        const columnName = this.config.tableConfigs[index].name;
        this.optionsSearchModifiers[columnName].dropDown.selectedItem = dropDownSelected;
        if (dropDownSelected.searchText) {
          this.searchParams[searchTC] = {
            mode: dropDownSelected.item.id,
            value: dropDownSelected.searchText
          };
        } else {
          delete this.searchParams[searchTC];
        }
      } else if (dropDownSelected.selected) {
        this.searchParams[searchTC] = dropDownSelected.item.id;
      } else {
        delete this.searchParams[searchTC];
      }
    }
    this.searchParams['page'] = this.config.page = 0;

    // if (JSON.stringify(this.searchParams) === '{"page":0,"size":10,"orderSort":"nameText","orderDir":"asc"}') {
    //   this.store.dispatch(fromActions.tableFiltersReset({module:this.config.module}));
    // }

    this.searchEmit(this.searchParams);
  }

  onDropdownMultiple(index: number, dropDownSelected: DropDownMultipleSelected) {
    this.showEmptyResult = false;
    this.pendingAction = true;
    const searchTC = this.config.tableConfigs[index].search;
    if (searchTC) {
      this.searchParams[searchTC] = dropDownSelected.items.map(item => item.id);
      if (this.searchParams[searchTC].length === 0) {
        delete this.searchParams[searchTC];
      }
    }
    this.searchParams['page'] = this.config.page = 0;

    this.searchEmit(this.searchParams);
  }

  onDropDownMenu(index: number, dropDownSelected: DropDownSelected) {
    this.action.emit({
      action: ActionsEnum.MASS_CHECK,
      data: dropDownSelected.item,
      index: index,
      row: dropDownSelected.data
    });
  }

  onDropDownBooleanSearch(index: number, dropDownSelected: DropDownSelected) {
    const searchTC = this.config.tableConfigs[index].search;
    if (searchTC) {
      let searchBoolean = undefined;
      switch (dropDownSelected.index) {
        case -1:
          searchBoolean = undefined;
          break;
        case 1:
          searchBoolean = true;
          break;
        case 2:
          searchBoolean = false;
          break;
      }
      if (searchBoolean === undefined) {
        delete this.searchParams[searchTC];
      } else {
        this.searchParams[searchTC] = searchBoolean;
      }
    }
    this.searchParams['page'] = this.config.page = 0;

    this.searchEmit(this.searchParams);
  }

  onClickMassCheckAll() {
    this.checkAll = !this.checkAll;
    this.data.forEach(item => {
      this.setMassCheck(item['id'], this.checkAll);
    });
  }

  onClickMassCheck(id: number) {
    const configChecked = this.searchParams['checked'].find((configCheck: any) => configCheck.id === id);
    this.setMassCheck(id, !configChecked.checked);
    this.checkAll = this.isAllCheckSelected();
  }

  setMassCheck(id: number, check: boolean) {
    this.searchParams['checked'].find((configCheck: any) => configCheck.id === id).checked = check;
    this.searchParams['checked'].sort((objA: any, objB: any) => {
      return objA.id > objB.id ? objA.id : objB.id;
    });
  }

  isAllCheckSelected() {
    return this.checkedModel.filter(checkModel => checkModel.checked).length === this.data.length;
  }

  checkShowIcon(row: number, items: any, paramsConf: any, action: any) {
    return (
      (!this.getDataByColumnName(row, paramsConf.name) || this.getDataByColumnName(row, paramsConf.name) === action) &&
      (!items.excludeActions || !items.excludeActions.includes(action))
    );
  }

  getAction(items: any, action: any, paramsConf: any) {
    if (items._action && paramsConf._actionsByData && paramsConf._actionsByData.includes(action)) {
      return items._action;
    }
    return action;
  }

  onClick3Dots() {
    this.eventShowMenuSubject.next();
  }

  onClick3DotsAction(rowIndex: number, item: any) {
    setTimeout(() => this.setOffSetDropDown(rowIndex), 0);
    this.dropdownsEvent[rowIndex].next(item);
  }

  getColumnColorStyle(items: any, paramsConf: any) {
    if (paramsConf.color && paramsConf.type === TypeEnum.COMBO_LOADING) {
      return { color: items[paramsConf.name + '_color'] };
    }
    return {};
  }

  onClickColumn(item: any, paramsConf: any) {
    if (paramsConf.clickable.path && item) {
      this.router.navigate([paramsConf.clickable.path, item[paramsConf.clickable.paramName]], {
        state: { refreshSearch: true }
      });
    } else {
      const hasContent: boolean = item[paramsConf._name] > 0;
      if (hasContent) {
        this.clickColumn.emit({ item: item, paramsConf: paramsConf });
      }
    }
  }

  getDataByColumnName(row: number, nameColumn: any) {
    return this.data[row][nameColumn];
  }

  showSecondRow(paramsConf: TableConfig) {
    return (
      paramsConf.search &&
      paramsConf.showInputSearch &&
      !paramsConf.type?.startsWith(TypeEnum.COMBO) &&
      paramsConf.type !== TypeEnum.INPUT
    );
  }

  onfilterEvent(index: number, event: boolean) {
    const data = {
      module: this.config.module,
      filterName: this.config.tableConfigs[index].search || ''
    };

    if (event) {
      this.store.dispatch(fromActions.tableFiltersAdd(data));
    } else {
      this.store.dispatch(fromActions.tableFiltersDelete(data));
    }
  }

  createOptionsSearchModifiers() {
    this.config.tableConfigs
      .filter((config: TableConfig) => this.typesSearchable.includes(config.type))
      .forEach(config => {
        const columnData: OptionalColumnDataHeadModel = new OptionalColumnDataHeadModel(
          config.name,
          config.search || ''
        );
        columnData.dropDown = this.createComboOfSearchModifier();
        let selectedId = 0;
        let searchText = undefined;
        let item: any;
        item = columnData.dropDown.dataItems[selectedId];
        if (config.search && this.searchParams[config.search]) {
          selectedId = this.searchParams[config.search].mode;
          searchText = this.searchParams[config.search].value;
          item = columnData.dropDown.dataItems.find((dataItem: { id: number }) => dataItem.id === selectedId);
        }
        columnData.dropDown.selectedItem = new DropDownSelectedItem(selectedId, item, false, searchText);

        this.optionsSearchModifiers[config.name] = columnData;
      });
  }

  private setOffSetDropDown(rowIndex: number) {
    const dropDown = document.getElementsByClassName('dropdown-menu d-block')[0] as any;
    if (dropDown) {
      dropDown.style['margin-left'] = '82px';
      const baseTop = 152;
      const rowHeight = 48;
      dropDown.style['margin-top'] = `${baseTop + rowHeight * rowIndex - this.scrollTop}px`;
      dropDown.style.visibility = 'visible';
    }
  }

  private createComboOfSearchModifier() {
    const optionsSearchModifier: DropDown = new DropDown();
    optionsSearchModifier.dataItems = searchModifiersUtil.map((modifier: any) => {
      const dropdownItem: DropdownItem = new DropdownItem(modifier.id, modifier.name);
      dropdownItem.svgIcon = {
        name: modifier.svgIcon,
        width: 18,
        height: 18,
        fill: '#4e5b73'
      };
      return dropdownItem;
    });
    return optionsSearchModifier;
  }

  isComboOrMultiple(paramsConf: TableConfig) {
    return [TypeEnum.COMBO.toString(), TypeEnum.COMBO_MULTIPLE.toString(), TypeEnum.COMBO_LOADING.toString()].includes(
      paramsConf.type
    )
      ? paramsConf.type
      : !paramsConf.type;
  }

  private searchEmit(searchParams: any) {
    if (JSON.stringify(searchParams) === JSON.stringify(this.searchParamsOLD)) return;
    this.searchParamsOLD = _.cloneDeep(searchParams);
    this.search.emit(searchParams);
    this.searchParams$.next(searchParams);

    if (this.hasParamSearch) setTimeout(() => this.createOptionsSearchModifiers(), 100);
  }

  onClearFilters() {
    this.showButtonClearFilters = false;
    this.optionsFormMultiple = [];
    this.selectOptionBoolean = undefined;
    this.selectOptionUnique = '';
    this.searchParams.selectedFavorite = false;
    this.searchParams = {
      page: 0,
      size: 10,
      clearFilter: true
    };

    this.searchEmit(this.searchParams);

    this.store.dispatch(fromActions.tableFiltersReset({ module: this.config.module }));
  }

  onSelectQuery(query: any, selectedFavorite = false) {
    this.optionsFormMultiple = [];
    this.selectOptionBoolean = undefined;
    this.selectOptionUnique = '';
    this.showButtonClearFilters = true;

    this.searchParams = JSON.parse(query);

    if (selectedFavorite) this.searchParams.selectedFavorite = selectedFavorite;

    if (this.searchParams) {
      if ('billing' in this.searchParams) {
        this.selectOptionBoolean = this.searchParams.billing;
      }
      if ('checked' in this.searchParams) {
        this.selectOptionBoolean = this.isActiveTerminal(this.searchParams.checked);
      }
      if ('consumedQuota' in this.searchParams) {
        this.selectOptionUnique = this.searchParams.consumedQuota;
      } else {
        setTimeout(() => this.opcionMultiple(), 0);
      }
    }

    this.searchModifiers();

    this.searchEmit(this.searchParams);
  }

  private isActiveTerminal(checkeds: any[]): boolean {
    return checkeds[0].data.state.color == 'OK';
  }

  private searchModifiers() {
    const serachList = [];
    for (const clave in this.searchParams) {
      if (
        this.searchParams[clave] != undefined &&
        clave !== 'page' &&
        clave !== 'size' &&
        clave !== 'orderDir' &&
        clave !== 'orderSort'
      ) {
        serachList.push(clave);
      }
    }

    serachList.forEach(serach => {
      const serachValue = this.searchParams[serach].value;
      const selectedItem = {
        index: 0,
        item: new DropdownItem(0, serachValue),
        selected: true,
        searchText: serachValue
      };
      let columnName = '';
      Object.values(this.optionsSearchModifiers).forEach((value: any) => {
        if (value.search === serach) {
          columnName = value.columnName;
        }
      });

      if (columnName) this.optionsSearchModifiers[columnName].dropDown.selectedItem = selectedItem;
    });
  }

  private opcionMultiple() {
    // if(this.data && this.data.length === 0 ) return;
    const palabrasABuscar = [];
    const optionsFormMultiple: any[] = [];

    // selecionar las palabras a buscar que contengan Search
    for (const clave in this.searchParams) {
      if (clave.includes('Search')) {
        if (this.searchParams[clave] != undefined) palabrasABuscar.push(clave);
      }
    }

    //me quedo con los combos con datos
    const comboData = this.config.tableConfigs.filter(f => f.data && f.data.length > 0);

    // recorro los filtros aplicado
    palabrasABuscar.forEach(palabraABuscar => {
      // de los combos que hay me quedo con los que me diga el query

      const config = comboData.find(f => f['_search'] == palabraABuscar);

      this.searchParams[palabraABuscar].forEach((item: any) => {
        const element = config?.data.find((f: any) => f.id === item);
        if (element) element.selected = true;
      });

      optionsFormMultiple.push(config?.data);
    });

    this.optionsFormMultiple = [...optionsFormMultiple];
  }

  onClickColumnEditable(event: Event, item: any, paramsConf: any, index: any, indexColumn: any) {
    this.showInput.forEach((item2: any, index2) => {
      item2.forEach((item3: any, index3: any) => {
        this.showInput[index2][index3] = false;
      });
    });

    if (!this.showInput[indexColumn] || !this.showInput[indexColumn][index]) {
      this.showInput[indexColumn][index] = true;
    }
    if (paramsConf.type === 'input') {
      this.nameEditable = { name: item[this.config.tableConfigs[indexColumn].name], id: item.id };
    } else {
      this.nameEditable = { name: item[this.config.tableConfigs[indexColumn].name], id: item.id, id2: item.clientId };
    }

    this.columnName = paramsConf.name;

    event.stopPropagation();
  }

  cancelEditTable(indice: any, indice2: any) {
    this.showInput[indice][indice2] = false;
  }

  saveEditTable(newValue: any, indice: any, indice2: any) {
    this.showInput[indice][indice2] = false;
    if (newValue) {
      this.editField.emit(newValue);
    }
  }

  onScrollOutSideHidden() {
    // this.config.tableConfigs.forEach((element: any, index: any) => {
    //   this.showInput[index] = [];
    // });
    // this.onClearFilters();
  }


  private checkDomain() {
    const domain = DomainService.getDomain();

    if(domain == DomainsEnum.AXESS){
      this.domainAxess = true;
      this.domainNms = false;
    }
  }

  trackByIndex = (i: number) => i;
}
