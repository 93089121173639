<div [ngClass]="{ axess: domainAxess, nms: domainNms }" *ngIf="currentTemplate === 'axess'">
  <div class="fondo">
    <div class="contenido">
      <span class="logo">
        <img class="imagen-logo" src="../../../assets/logos/LogoBlancoFranjaAzul.png" alt="My Happy SVG" />
      </span>
      <span class="logo2">
        <img class="imagen-logo2" src="../../../assets/logos/MONITOREO.svg" alt="My Happy SVG" />
      </span>
      <form [formGroup]="acceso">
        <div class="caja">
          <div class="container">
            <label class="inicio-sesion">{{ 'LOGIN.TITTLE_AXESS' | translate }}</label>
            <label class="credenciales">{{ 'LOGIN.SUBTITTLE.LABEL_AXESS' | translate }}</label>
            <label class="usuario"> {{ 'LOGIN.LABEL.USER' | translate }}</label>
            <div class="wrapper">
              <span class="icon-user icon-user-lg"></span>
              <input
                class="input"
                type="text"
                placeholder="samplemail@gmail.com"
                name="uname"
                formControlName="correo"
                required
                [ngClass]="{ error: errorCorreo }" />
              <p class="letra-error" *ngIf="errorCorreo">
                {{ 'LOGIN.LABEL.ERRORS.ERROR_USER' | translate }}
              </p>
            </div>

            <label class="contraseña">{{ 'LOGIN.LABEL.PASSWORD' | translate }}</label>
            <div class="wrapper">
              <span class="icon-pass icon-candado-lg"></span>
              <input
                class="input"
                type="password"
                placeholder="········"
                name="psw"
                formControlName="clave"
                required
                [ngClass]="{ error: errorCLave }" />
              <p class="letra-error" *ngIf="errorCLave">
                {{ 'LOGIN.LABEL.ERRORS.ERROR_PASSWORD' | translate }}
              </p>
              <!-- cuando se rellene y sea erroneo, se pondra la clase error para que lo resalte. -->
            </div>

            <div class="wrapper">
              <p class="unauthorized mt-1 mb-0 p-1" *ngIf="errorLogin">
                {{ 'LOGIN.LABEL.ERRORS.UNAUTHORIZED' | translate }}
              </p>
              <p class="unauthorized mt-1 mb-0 p-1" *ngIf="errorLocked">
                {{ 'LOGIN.LABEL.ERRORS.LOCKED' | translate }}
              </p>
            </div>

            <!-- <label class="recor">
                  <div class="pba-check">
                      <input class="pba-check-input" id="checkbox6" type="checkbox" >
                      <label for="checkbox6" class="pba-check-text">Recordar contraseña</label>
                      <label for="checkbox6" class="pba-check-mark"></label>
                    </div>
      
              </label> -->
            <button type="submit" (click)="enviar()">
              {{ 'LOGIN.LABEL.ENTER' | translate }}
            </button>
            <span>
              <a class="span-a" href="https://im.hispasat.es/adim/#/SignIn" target="_blank" rel="noopener noreferrer">{{
                'LOGIN.LABEL.FORGOT' | translate
              }}</a>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div [ngClass]="{ axess: domainAxess, nms: domainNms }" *ngIf="currentTemplate === 'nms'">
  <div class="fondo">
    <div class="contenido">
      <span class="logo">
        <img class="inverse-color" src="../../../assets/logos/AF_HISPASAT_WM_POS_MONO_RGB.svg" alt="My Happy SVG" />
      </span>
      <form [formGroup]="acceso">
        <div class="caja">
          <div class="container">
            <label class="inicio-sesion">{{ 'LOGIN.TITTLE' | translate }}</label>
            <label class="credenciales">{{ 'LOGIN.SUBTITTLE.LABEL' | translate }}</label>
            <label class="usuario"> {{ 'LOGIN.LABEL.USER' | translate }}</label>
            <div class="wrapper">
              <span class="icon-user icon-user-lg"></span>
              <input
                class="input"
                type="text"
                placeholder="samplemail@gmail.com"
                name="uname"
                formControlName="correo"
                required
                [ngClass]="{ error: errorCorreo }" />
              <p class="letra-error" *ngIf="errorCorreo">
                {{ 'LOGIN.LABEL.ERRORS.ERROR_USER' | translate }}
              </p>
            </div>

            <label class="contraseña">{{ 'LOGIN.LABEL.PASSWORD' | translate }}</label>
            <div class="wrapper">
              <span class="icon-pass icon-candado-lg"></span>
              <input
                class="input"
                type="password"
                placeholder="········"
                name="psw"
                formControlName="clave"
                required
                [ngClass]="{ error: errorCLave }" />
              <p class="letra-error" *ngIf="errorCLave">
                {{ 'LOGIN.LABEL.ERRORS.ERROR_PASSWORD' | translate }}
              </p>
              <!-- cuando se rellene y sea erroneo, se pondra la clase error para que lo resalte. -->
            </div>

            <div class="wrapper">
              <p class="unauthorized mt-1 mb-0 p-1" *ngIf="errorLogin">
                {{ 'LOGIN.LABEL.ERRORS.UNAUTHORIZED' | translate }}
              </p>
              <p class="unauthorized mt-1 mb-0 p-1" *ngIf="errorLocked">
                {{ 'LOGIN.LABEL.ERRORS.LOCKED' | translate }}
              </p>
            </div>

            <!-- <label class="recor">
                  <div class="pba-check">
                      <input class="pba-check-input" id="checkbox6" type="checkbox" >
                      <label for="checkbox6" class="pba-check-text">Recordar contraseña</label>
                      <label for="checkbox6" class="pba-check-mark"></label>
                    </div>
      
              </label> -->
            <button type="submit" (click)="enviar()">
              {{ 'LOGIN.LABEL.ENTER' | translate }}
            </button>
            <span>
              <a
                class="login__link-forget-password"
                href="https://im.hispasat.es/adim/#/SignIn"
                target="_blank"
                rel="noopener noreferrer"
                >{{ 'LOGIN.LABEL.FORGOT' | translate }}</a
              >
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
