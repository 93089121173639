import { Injectable } from '@angular/core';
import { LangService } from '@shared/services/lang/lang.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigTableService {
  constructor(private http: HttpClient, private langService: LangService) {}

  getConfigByModule(code: any) {
    return this.http.get<any>( this.langService.getUrlLang() + '/config-table-view/' + code, {});
  }

  getConfigByUser(code: any) {
    return this.http.get<any>( this.langService.getUrlLang() + '/config-table-view/' + code + '/byuser', {});
  }

  createConfig(code: any, configToSave: any) {
    return this.http.post<any>( this.langService.getUrlLang() + '/config-table-view/' + code, configToSave, {
      observe: 'body',
      responseType: 'json'
    });
  }

  reset(code: any) {
    return this.http.delete<any>( this.langService.getUrlLang() + '/config-table-view/' + code, {});
  }
}
