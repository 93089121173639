/**
 * These are icons by default, there is a special case, TYPE_BY_DATA, whose icon is defined by data in the _actions field,
 * which is an array of icons by position in the column.
 */
export enum ActionsEnum {
  TERMINAL_WIFI = 'terminal-wifi-blue',
  ROUTER_WIFI = 'router-wifi-blue',
  DELETE = 'delete-blue',
  EDIT = 'edit-blue',
  MONITORING = 'monitoring-blue',
  CHECK = 'check-blue',
  ADD = 'add-blue',
  CONFIRM = 'confirm-blue',
  ON = 'sign-off-blue',
  OFF = 'sign-off-red',
  SHARE = 'share-blue',
  HISTORIC = 'historic-blue',
  ASSIGNMENT = 'assignment',
  SEE = 'search-blue',
  EXECUTE = 'forward-blue',
  DOWNLOAD = 'download-blue',
  VIEW = 'direccion-ip-blue',
  MASS_CHECK = 'mass_check',
  TERMINAL = 'receptor-blue',
  UNDO = 'undo-blue',
  EYE = 'eye-blue',
  TAB = 'tab-blue',
  MAC = 'force-activation-blue',
  UNBLOCK = 'unblocked-blue',
  SPEED = 'meter-blue',
  ACTIVATE = 'play2-blue',
  OPTIONS = 'options',
  WIFI_ON = 'wifi-off-blue',
  WIFI_OFF = 'wifi-off-red',
  SWITCH_ON = 'switch-on',
  SWITCH_OFF = 'switch-off',
  ADD_PHYS_ID = 'force-activation-blue',
  CLOCK = 'clock-blue',
  MIGRATION = 'change-platform-blue',
  QTS = 'qts-blue',
  OLD_SERIAL_NUMBER = 'serial-number-blue',
  PROVISIONAL_REMOVE = 'provisional-delete-blue',
  DEFINITY_REMOVE = 'perma-delete-blue',
  ALERT = 'alert-blue',
  MONITORING_PRTG = 'monitoring-prtg-blue',
  CHANGE_CLIENT = 'change-client-blue',
  EXTERNAL_MONITORING = 'forward-blue',//change name icon
  EXTERNAL_TOOL = 'wrench-blue'
}
