<div class="dropdown pba-dropdown w-100" [ngClass]="{ 'd-flex pba-input-block': inputMode }">
  <ng-container *ngIf="inputMode; else showButton">
    <div (click)="onClickButtonDropDown()" data-bs-toggle="dropdown" class="pba-input-block__icons">
      <svg-icon
        *ngIf="list[selected || 0].svgIcon"
        class="me-1 pba-input-block__icon-selected d-flex"
        name="{{ list[selected || 0].svgIcon?.name }}"
        [svgStyle]="{ 'width.px': 28, 'height.px': 18 }">
      </svg-icon>
      <div class="pba-input-block__icon">
        <i class="pba-input-block__icon--pos"></i>
      </div>
    </div>

    <input
      type="text"
      class="pba-input-text pba-input-block__text form-control"
      #keyupHeadDD
      [(ngModel)]="inputSearch"
      (input)="onKeyHeadSearch(keyupHeadDD.value)" />
  </ng-container>
  <ng-template #showButton>
    <label *ngIf="placeholder" class="col-form-label pba-input-label">{{ placeholder }}</label>

    <button
      class="pba-dropdown-button form-control dropdown-toggle align-items-center"
      type="button"
      data-bs-toggle="dropdown"
      [ngClass]="{ disabled: disabled }"
      *ngIf="list && list[selected || 0]"
      (click)="onClickButtonDropDown()">
      <i *ngIf="icon !== undefined" class="icon-arrow me-1 d-flex align-self-center icon-{{ icon }}-lg"></i>

      <ng-container *ngIf="title">
        <div class="d-flex flex-column">
          <span class="b-dropdown button__title">
            {{ title | translate }}
          </span>
          <span *ngIf="subtitle" class="b-dropdown w-100">
            {{ subtitle | translate }}
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="title === undefined">
        <ng-container *ngIf="list[selected || 0].nameParams; else wintoutParams">
          <span class="b-dropdown">
            {{ list[selected || 0].name | translate : '{params:' + list[selected || 0].nameParams + '}' }}
          </span>
        </ng-container>
      </ng-container>
      <ng-template #wintoutParams>
        <svg-icon
          *ngIf="list[selected || 0].svgIcon"
          class="me-1"
          name="{{ list[selected || 0].svgIcon?.name }}"
          [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
        </svg-icon>
        <span *ngIf="!multiple; else textMultiple" class="b-dropdown">
          {{ list[selected || 0].name | translate }}
        </span>
        <ng-template #textMultiple>
          <span class="b-dropdown" data-toggle="tooltip" title="{{ listStr }}">
            {{ listStr }}
          </span>
        </ng-template>
      </ng-template>
    </button>
  </ng-template>
  <div
    #dropdownRef
    (scroll)="onScroll()"
    class="dropdown-menu p-0"
    (blur)="multiple && onBlurOptionsDropdown()"
    tabindex="0">
    <div *ngIf="searchBox" class="search-select2">
      <input
        type="text"
        #search
        class="pba-input-search m-1 w-100"
        [(ngModel)]="searchText"
        (focus)="multiple && onFocusInputSearch()"
        (blur)="multiple && onBlurInputSearch()"
        (ngModelChange)="onChangeTextSearch($event)"
        (keydown.arrowdown)="onKeyDownArrowDown()"
        (keydown.arrowup)="onKeyDownArrowUp()"
        (keydown.enter)="onKeyUpSelected($event)" />
    </div>
    <ul class="menu-selec2 p-0">
      <ng-container *ngFor="let item of list; let i = index; trackBy: trackById">
        <li
          *ngIf="item.show"
          #dropdownItemRef
          class="li-dropdown dropdown-item d-flex"
          id="{{ item.id }}"
          (click)="onClickDropDown(item, $event, i)"
          translate
          [translateParams]="{ params: item.nameParams }"
          title="{{ item.name }}"
          [ngClass]="{ focus: item.focus }">
          <div *ngIf="multiple" class="pba-check" (click)="onClickDropDown(item, $event)">
            <input
              class="pba-check-input"
              id="{{ 'checkbox' + i + item.id }}"
              type="checkbox"
              [checked]="item.selected" />
            <label class="pba-check-text" for="{{ 'checkbox' + i + item.id }}"></label>
            <label for="{{ 'checkbox' + i + item.id }}" class="pba-check-mark"></label>
          </div>
          <svg-icon
            *ngIf="item.svgIcon"
            class="me-1"
            name="{{ item.svgIcon.name }}"
            [svgStyle]="{
              'width.px': item?.svgIcon?.width || 28,
              'height.px': item?.svgIcon?.height,
              fill: item?.svgIcon?.fill
            }">
          </svg-icon>
          {{ item.name }}
        </li>
      </ng-container>
    </ul>
  </div>

  <div
    *ngIf="showButtonClearFilters"
    (click)="clearfiltersEmit()"
    class="button-clear-filters"
    [ngStyle]="{ 'margin-top': inputMode ? '16px' : '8px' }">
    x
  </div>
</div>
