import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LangService } from '@shared/services/lang/lang.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  constructor(private http: HttpClient, private langService: LangService) {}

  getFavorites(configTableModule: string) {
    return this.http.get<any>( this.langService.getUrlLang() + `/config-table-view/favorite/${configTableModule}`, {});
  }

  newFavorite(configTableModule: string, body: any): any {
    return this.http.post<any>( this.langService.getUrlLang() + `/config-table-view/favorite/${configTableModule}`, body);
  }

  deleteFavorite(idFavorite: number): any {
    return this.http.delete<any>( this.langService.getUrlLang() + `/config-table-view/favorite/${idFavorite}`);
  }
}

// import { HttpClient } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { ConfigModules } from '@shared/models/common/config-modules.enum';
// import { of } from 'rxjs';

// interface Item {
//   id: number;
//   name: string;
//   query: string;
// }

// interface List {
//   module: string;
//   data: Item[];
// }

// @Injectable({
//   providedIn: 'root'
// })
// export class FavoritesService {
//   list: List[] = [];

//   constructor(private http: HttpClient) {
//     const item: Item = {
//       id: 1,
//       name: '5',
//       query: '{"page":0,"size":10,"orderSort":"nameText","orderDir":"asc","nameText":{"mode":"3","value":"5"}}'
//     };

//     const item2: Item = {
//       id: 2,
//       name: 'admin',
//       query: '{"page":0,"size":10,"clientTypeIdListSearch":[1],"clientTypeIdList":{"idList":[1]}}'
//     };

//     const item3: Item = {
//       id: 3,
//       name: 'quantis hughes',
//       query:
//         '{"page": 0,"size": 10,"orderSort": "nameText","orderDir": "asc","clientIdList": {"idList": [60]},"platformIdList": {"idList": [2]},"platformIdListSearch": [2],"clientIdListSearch": [60]}'
//     };

//     const item4: Item = {
//       id: 4,
//       name: 'aurona quantis',
//       query:
//         '{"page": 0,"size": 10,"clearFilter": true,"orderSort": "nameText","orderDir": "asc","clientIdList": {"idList": [60]},"clientIdListSearch": [60],"nameText": {"mode": "3","value": "eurona"}}'
//     };

//     const item5: Item = {
//       id: 5,
//       name: 'CUENTAS',
//       query: '{"page": 0,"size": 10,"orderSort": "nameText","orderDir": "asc","billing":true}'
//     };

//     const item6: Item = {
//       id: 6,
//       name: 'VNO',
//       query: '{"page": 0,"size": 10,"orderSort": "nameText","orderDir": "asc","billing":false}'
//     };

//     const item7: Item = {
//       id: 7,
//       name: 'cuota consumida',
//       query: '{"page": 0,"size": 10,"consumedQuota": "consumed"}'
//     };

//     this.list.push({
//       module: ConfigModules.CLIENT,
//       data: [item, item2]
//     });

//     this.list.push({
//       module: ConfigModules.SLOT,
//       data: [item3, item4, item5, item6]
//     });

//     this.list.push({
//       module: ConfigModules.TERMINAL,
//       data: [item7]
//     });
//   }

//   getFavorites(configTableModule: string) {
//     return of(this.list.find(f => f.module === configTableModule));
//   }

//   newFavorite(module: string, body: any): any {
//     const lista = this.list.find(f => f.module === module);

//     if (lista === undefined) {
//       const item: any = {
//         module: module,
//         data: []
//       };

//       item.data.push({
//         id: this.list.length + 1,
//         name: body.name,
//         query: body.query
//       });

//       this.list.push(item);
//     } else {
//       lista.data.push({
//         id: this.list.length + 1,
//         name: body.name,
//         query: body.query
//       });
//     }

//     return of({
//       ok: true,
//       status: 200
//     });
//   }

//   deleteFavorite(idFavorite: number): any {
//     this.list.map((m: any) => {
//       m.data.filter((f: any) => f.id !== idFavorite);
//     });

//     return of({
//       ok: true,
//       status: 200
//     });
//   }
// }
