import { ClientTypeEnum } from './client-type.enum';

export const clientTypeWeight: any = {
  [ClientTypeEnum.ROLE_CLIENT_ADMIN]: 1,
  [ClientTypeEnum.ROLE_CLIENT_ADMIN_DOMAIN]: 2,
  [ClientTypeEnum.ROLE_CLIENT_VNO]: 3,
  [ClientTypeEnum.ROLE_CLIENT_CUENTAS]: 4,
  [ClientTypeEnum.CLIENTTYPE_BILLABLE]: 4,
  [ClientTypeEnum.ROLE_CLIENT_EXTERNAL]: 5,
  [ClientTypeEnum.CLIENTTYPE_TERMINAL]: 5
};
